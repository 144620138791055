/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  height: 111px;
  width: 100%;
  background-color: #FFFEEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: initial;
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 26px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .card {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 130px;
    padding-left: 0;
  }
}

.head {
  position: relative;
  background-color: #FFF970;
  color: #989898;
  padding-right: 14px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headInfo {
  display: flex;
  align-items: center;
}

.icon, .starIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 21px;
  width: 19px;
  margin-right: 13px;
}

.icon {
  background-image: url("../../../img/icons/priority-ad-icon.svg");
}

.starIcon {
  background-image: url("../../../img/icons/priority-ad-star.svg");
}

.menuButton {
  position: relative;
  background-image: url("../../../img/icons/dots-menu-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  cursor: pointer;
}

.cardPopup {
  right: -16px;
  top: 30px;
}
@media (min-width: 1440px) {
  .cardPopup {
    right: -3px;
    top: 28px !important;
  }
}

.contentContainer {
  padding-top: 10px;
  padding-right: 11px;
  padding-left: 17px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .contentContainer {
    padding-left: 18px;
    padding-right: 21px;
  }
}

.textContainer {
  max-width: 70%;
}

.title {
  color: #000000;
}
@media (min-width: 1440px) {
  .title {
    font-size: 16px;
  }
}

.infoBlock {
  margin-top: 7px;
  color: #989898;
  display: flex;
}
@media (min-width: 1440px) {
  .infoBlock {
    margin-top: 16px;
  }
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.playerContainer {
  padding-right: 18px;
}
@media (max-width: calc(1440px - 1px)) {
  .playerContainer {
    padding-left: 14px;
  }
}