/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container :global(.rdrCalendarWrapper) {
  background-color: transparent;
  border: none;
  width: 100%;
}
.container :global(.rdrCalendarWrapper) :global(.rdrWeekDays) {
  display: none;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonthAndYearPickers) {
  display: flex;
  justify-content: space-around;
}
.container :global(.rdrCalendarWrapper) :global(.rdrNextPrevButton) {
  display: none;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) {
  width: 100%;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDayPassive.rdrDay) {
  pointer-events: auto;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) {
  width: 14.2857142857% !important;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrDayWeekend) {
  color: #E53935 !important;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrDayWeekend) :global(.rdrDayNumber) {
  color: #E53935 !important;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrDayWeekend) :global(.rdrDayNumber) span {
  color: #E53935 !important;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrDayEndPreview) {
  display: none;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrDayStartPreview) {
  display: none;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :global(.rdrSelected) {
  width: 44px;
  height: 44px;
  left: calc((100% - 44px) / 2);
  border-radius: 50%;
  top: calc((44px - 100%) / 2);
  background-color: #E53935;
}
.container :global(.rdrCalendarWrapper) :global(.rdrMonth) :global(.rdrDay) :after {
  display: none;
}