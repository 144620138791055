/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 24px;
  }
}

.buttonContainer {
  width: 100%;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .buttonContainer {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
  }
}

.createButton {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 44px;
  width: auto;
  background-image: url("../../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .createButton {
    background-position: left 12px center;
    padding-left: 22px;
  }
}
@media (min-width: 1440px) {
  .createButton {
    order: 3;
    width: auto;
    padding-left: 56px;
    padding-right: 22px;
    background-size: 20px;
    font-size: 14px;
    justify-content: flex-start;
  }
}