/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .pageSettings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
  }
}

.buttonBack {
  width: 40%;
  background: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
  margin-top: 10px;
  box-shadow: none;
  border-radius: 5px;
}
.buttonBack:active, .buttonBack:hover {
  color: #E53935;
}
@media (max-width: calc(1440px - 1px)) {
  .buttonBack {
    margin-left: 15px;
  }
}

.buttonsContainer {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    padding: 0;
    order: 2;
  }
}

.archiveButton {
  background-size: 30px 22px;
  background-position: 9px center;
  background-repeat: no-repeat;
  background-image: url("../../../../img/icons/maintenance-archive-icon.svg");
}
@media (min-width: 1440px) {
  .archiveButton {
    background-position: 12px center;
    min-width: 140px;
    height: 44px;
    border-radius: 10px;
    margin-left: 15px;
    padding-left: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

.addButton {
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .addButton {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background-image: url("../../../../img/icons/plus-icon.svg");
    background-repeat: no-repeat;
    background-size: 22px;
    box-shadow: none;
    margin-right: 25px;
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
  .addButton:hover {
    color: #000;
  }
}

.dateInputContainer {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
}
@media (min-width: 1440px) {
  .dateInputContainer {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .dateInput {
    min-width: 327px;
  }
}
.dateInput label {
  color: #000000;
}

.listContainer {
  padding-top: 16px;
}

.spinnerContainer {
  padding-top: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}