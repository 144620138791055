@import '../../../../styles/mixins';

.container {
    display: inline-block;
    text-transform: uppercase;
    padding-left: 6px;

    @include min-width(lg) {
        padding-left: 8px;
    }
}

.status {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    display: inline-block;
    background-color: #989898;
    margin-right: 5px;

    @include min-width(lg) {
        height: 12px;
        width: 12px;
        margin-right: 8px;
    }
}

.statusOnline {
    background-color: #02E30B;
}
