/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (min-width: 1440px) {
  .controlsContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

.searchContainer {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .searchContainer {
    padding-bottom: 20px;
  }
}

.searchType {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  background-color: transparent;
  display: none;
}
@media (min-width: 1440px) {
  .searchType {
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 20px;
  }
}
@media (max-width: calc(1440px - 1px)) {
  .listContainer {
    padding-top: 20px;
  }
}

.buttonsContainer {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  background-color: transparent;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    max-width: 320px;
    height: auto;
    padding-top: 0;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .hideMobile {
    display: none;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}