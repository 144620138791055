/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background-color: #FFFBFB;
  height: 108px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 26px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .card {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px !important;
  }
}

.head {
  background-color: #F16360;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 8px;
  text-transform: uppercase;
}

.headInfo {
  display: flex;
  align-items: center;
}

.icon {
  background-image: url("../../../img/icons/mediaplan-card-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 21px;
  width: 19px;
  margin-right: 13px;
}

.headCount {
  border: 1px solid #989898;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  color: #989898;
}

.contentContainer {
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1440px) {
  .contentContainer {
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.title {
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
@media (min-width: 1440px) {
  .title {
    font-size: 16px;
  }
}

.infoBlock {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 75%;
}
@media (min-width: 1440px) {
  .infoBlock {
    max-width: 40%;
    line-height: 16px;
  }
}

.infoTitle {
  color: rgba(0, 0, 0, 0.25);
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
}