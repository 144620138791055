/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  margin-top: 30px;
}
.container.wide select {
  min-width: 160px;
}
.container select {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  background-color: #FFFFFF;
  min-width: 84px;
  height: 34px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  text-align-last: center;
  background-image: url("../../../img/icons/select-triangle.svg");
  background-size: 10px 5px;
  background-position: center right 12px;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .container select {
    font-size: 13px;
  }
}
.container select:focus {
  outline: none;
}
.container label {
  color: #3C2E2E;
}