/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  position: relative;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .container {
    padding-top: 37px;
  }
}

.breadcrumbs {
  margin-bottom: 0;
}

@media (min-width: 1440px) {
  .recordControlsContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 54px;
  }
}