/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .searchContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

.search {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .search {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 20px;
  }
}

.toggleAll {
  justify-content: flex-start;
}

.endBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.back, .ready, .save {
  width: 152px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  border: 0;
  cursor: pointer;
  margin-top: 11px;
}

.buttons {
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #d9d9d9;
  position: fixed;
  bottom: 0;
  background-color: #FAFAFA;
}
@media (max-width: calc(1024px - 1px)) {
  .buttons {
    justify-content: space-between;
  }
}

.ready {
  background: #FF3B30;
  color: #fff;
  margin-left: 10px;
  margin-right: 50px;
}